<template>
  <DarkOverlay :shown="shown" @click="hide" />

  <div
    :class="{
      'select-dropdown': true,
      'with-icon': icon,
      'select-shown': shown,
      'inline-mode': inlineMode
    }"
    :style="`min-width: ${maxWidth}`"
    v-bind="$attrs"
  >
    <Dropdown
      :duration="0.25"
      :parent-opened="shown"
      :on-open="open"
      :direction="direction"
      :on-close="hide"
      v-bind="$attrs"
    >
      <template #dropdown-title>
        <div v-if="selectedItem">
          <div
            v-if="selectedItem.html"
            class="select-option selected-option"
            v-html="selectedItem.html"
          />
          <div v-else class="select-option selected-option">
            <svg-icon v-if="icon" :icon="icon" class="select-option-icon" />
            <span class="select-option-value">{{ selectedItem.name }}</span>
          </div>
        </div>

        <div v-else class="select-option">
          <div class="select-option-value">{{ placeholder }}</div>
        </div>
      </template>
      <template #arrow>
        <div class="arrow">
          <SortButton />
        </div>
      </template>
      <template #dropdown-content>
        <ul class="select-list">
          <li
            v-for="{ id, name, selected, html } in items"
            :key="id"
            :ref="setNodeRef"
            class="select-list--item"
            @click="() => selectHandler(id)"
          >
            <div
              v-if="html"
              :class="
                `select-option ${
                  selected ? 'select-option--selected' : ''
                } d-flex justify-content-between`
              "
            >
              <span v-html="html" />
              <AppRadio :id="id" :checked="selected" name="dropdown" />
            </div>
            <div
              v-else
              :class="
                `select-option ${selected ? 'select-option--selected' : ''}`
              "
            >
              <span class="select-item-name">{{ name }}</span>
              <svg-icon
                v-if="checkType === 'checkmark'"
                icon="checkmark"
                class="checkmark"
              />
              <AppRadio
                v-else
                :id="id"
                :checked="selected"
                name="dropdown"
                class="ms-auto"
              />
            </div>
          </li>
        </ul>
      </template>
    </Dropdown>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import Dropdown from "@/components/ui/Dropdown/Dropdown";
import SvgIcon from "@/components/ui/SvgIcon";
import SortButton from "@/components/ui/SortButton";
import DarkOverlay from "@/components/ui/DarkOverlay";
import { validator } from "@/components/ui/Dropdown/dropdown.helpers";
import AppRadio from "@/components/Form/AppRadio";

export default {
  name: "SelectDropdown",
  components: { AppRadio, DarkOverlay, SortButton, SvgIcon, Dropdown },
  props: {
    inlineMode: { type: Boolean, default: false },
    placeholder: { type: String, required: false },
    checkType: {
      type: String,
      validator: c => ["checkmark", "radio"].indexOf(c) !== -1,
      default: "checkmark"
    },
    list: {
      type: Array,
      required: true
    },
    icon: {
      type: String,
      required: false
    },

    onSelect: {
      type: Function,
      required: true
    },

    hideOnSelect: {
      type: Boolean,
      default: true
    },

    direction: {
      type: String,
      default: "down",
      validator: dir => validator(dir)
    }
  },

  data() {
    return {
      items: this.list,
      shown: false,
      nodes: [],
      maxWidth: 0,
      resizeTimeout: null
    };
  },

  computed: {
    selectedItem() {
      const selected = this.list.find(({ selected }) => selected);
      if (selected) {
        return selected;
      }

      return this.placeholder ? null : this.list[0] ?? null;
    }
  },
  watch: {
    shown(status) {
      this.toggleOverlay(status);
    }
  },

  methods: {
    ...mapMutations(["toggleOverlay"]),
    setNodeRef(ref) {
      if (this.nodes.length < this.items.length && ref) {
        this.nodes.push(ref);
      }
    },

    updateMaxWidth(useTimeout = true) {
      if (this.nodes.length) {
        clearTimeout(this.resizeTimeout);

        this.resizeTimeout = setTimeout(
          () => {
            this.maxWidth = "unset";
            setTimeout(() => {
              this.maxWidth = `
                ${Math.max(
                  ...this.nodes.map(node => {
                    return parseInt(window.getComputedStyle(node).width);
                  })
                )}px`;
            }, 20);
          },
          useTimeout ? 200 : 0
        );
      }
    },

    hide() {
      this.shown = false;
    },

    open() {
      this.shown = true;
    },
    selectHandler(id) {
      if (this.hideOnSelect) {
        this.hide();
      }

      this.onSelect(id);
    }
  },
  mounted() {
    this.updateMaxWidth(false);

    window.addEventListener("resize", this.updateMaxWidth);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateMaxWidth);
  }
};
</script>

<style lang="scss">
.select-dropdown {
  position: relative;
  height: $field-height;
  min-width: 170px;
  @include underOverlay();
  .select-option {
    padding: 0 $field-padding-x-with-icon 0 1rem;
  }

  &.with-icon {
    .select-option {
      padding: 0 $field-padding-x-with-icon;
    }
  }

  &.inline-mode {
    .dropdown.hidden {
      background-color: transparent;
      box-shadow: none;
    }
  }

  .dropdown {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: #fff;
    width: max-content;
    min-width: 100%;
    border-radius: $field-radius;
    box-shadow: $shadow-700;
    overflow: hidden;

    &-up {
      top: auto;
      bottom: 0;
    }

    &-heading {
      height: $field-height;

      .arrow {
        margin-right: 1rem;
        margin-left: auto;
        display: flex;
        align-items: center;
        transition: transform $transition;
      }
    }

    &-body {
      transition: padding-top 0.2s;

      .select-list--item {
        .select-option {
          padding-top: 0.7em;
          padding-bottom: 0.7em;
          cursor: pointer;

          display: flex;

          .checkmark {
            display: block;
            margin-left: auto;
            color: $color-success;
            transform: scale(0);
            transition: $transition;
          }

          &--selected {
            font-weight: 600;

            .checkmark {
              opacity: 1;
              transform: scale(1);
            }
          }
        }

        &:first-child {
          .select-option {
            margin-top: 0.3em;
          }
        }

        &:last-child {
          margin-bottom: 0.6em;
        }

        @media (any-hover: hover) {
          transition: $transition;
          &:hover {
            background-color: rgba($grey-200, 0.2);
          }
        }
      }
    }

    &.shown {
      .arrow {
        transform: rotate(180deg);
      }
    }

    &-up {
      .select-list--item {
        &:last-child {
          margin-bottom: 0;
        }
        &:first-child {
          margin-top: 0.5rem;
        }
      }
    }
  }

  &.select-shown {
    @include aboveOverlay();
  }

  .selected-option {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center;
  }

  .select-option-icon {
    transform: rotate(0) !important;
    font-size: 1rem !important;
    margin-right: 0.5em !important;
  }

  .arrow {
    margin-left: auto;
  }

  .select-list,
  .select-option-value {
    text-transform: capitalize;
  }
}
</style>
