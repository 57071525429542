<template>
  <div
    class="pagination-wrapper d-flex align-items-center justify-content-center"
  >
    <v-pagination
      v-model="page"
      :pages="total"
      :range-size="1"
      active-color="#DCEDFF"
      @update:modelValue="updateHandler"
    />
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
  name: "Pagination",
  components: { VPagination },
  props: {
    showPages: {
      type: Number,
      default: 4
    },
    total: {
      type: Number,
      required: true
    },
    current: {
      type: Number,
      required: true
    }
  },
  emits: {
    changePage: null
  },

  data() {
    return {
      page: this.current
    };
  },

  watch: {
    current(page) {
      if (page !== this.page) {
        this.page = page;
      }
    },

    page(page) {
      if (page !== this.current) {
        this.$emit("changePage", page);
      }
    }
  },

  methods: {
    updateHandler(page) {
      this.page = page;
    }
  }
};
</script>

<style lang="scss">
.pagination {
  padding-left: 2rem;
  padding-right: 2rem;
  &-item {
    font-size: 15px;
    line-height: 1;
    padding: 0.67em 1em;
    border-radius: 12px;
    border: 1px solid transparent;
    margin-right: 0.4em;
    cursor: pointer;

    &:not(.shown) {
      opacity: 0;
      pointer-events: none;
    }

    .icon {
      font-size: 10px;
    }

    &.current {
      border-color: $color-contrast-dark;
    }

    &.dots {
      padding-left: 0;
      padding-right: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.Pagination {
  .PaginationControl {
    $space: 50px;
    &:first-child,
    &:last-child {
      display: none !important;
    }

    &:nth-child(2) {
      margin-right: $space;
    }

    &:nth-last-child(2) {
      margin-left: $space;
    }
  }

  .DotsHolder {
    svg {
      display: none;
    }

    position: relative;
    &:before {
      content: "...";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .Page {
    padding: 10px 14px;
    font-size: 15px;
    width: max-content;
    height: auto;
    border-radius: 12px;
    line-height: 1;
    border: 1px solid transparent;

    &-active,
    &-active:focus {
      background-color: transparent !important;
      border-color: currentColor !important;
    }
  }
}
</style>
