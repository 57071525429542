<template>
  <svg
    viewBox="0 0 42 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="menu-top"
    preserveAspectRatio="none"
  >
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="42"
      height="33"
    >
      <rect width="42" height="33" fill="#C4C4C4" />
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M-55 46C-55 39.3726 -49.6274 34 -43 34H-5C1.62742 34 7 28.6274 7 22V12C7 5.37258 12.3726 0 19 0H30C36.6274 0 42 5.37258 42 12V34V153C42 159.627 36.6274 165 30 165H-43C-49.6274 165 -55 159.627 -55 153V46Z"
        fill="white"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "MenuTop"
};
</script>

<style scoped></style>
