<template>
  <div class="w-100 row align-items-center">
    <div class="col-2 d-flex align-items-center">
      <p class="count-info">{{ countText }}</p>
    </div>

    <div v-if="pagination" class="col-8">
      <Pagination
        v-if="pagination.total > 1"
        :total="pagination.total"
        :current="pagination.current"
        @changePage="changePageHandler"
      />
    </div>

    <div class="col-2 d-flex justify-content-end align-items-center">
      <SelectDropdown
        v-if="useSelect && rowsCountList.length"
        :on-select="onRowsCountSelected"
        :list="rowsCountList"
        direction="up"
      />
    </div>
  </div>
</template>

<script>
import { ref, watch, computed } from "vue";
import Pagination from "@/components/ui/Pagination/Pagination";
import SelectDropdown from "@/components/ui/Dropdown/SelectDropdown";
const PER_PAGE_COUNTS = [10, 20, 30];

export default {
  name: "TableFooter",

  components: { SelectDropdown, Pagination },

  props: {
    useSelect: { type: Boolean, default: true },
    showCount: { type: Number, default: 20 },
    perPage: { type: Number, default: 20 },
    totalRows: { type: Number, default: 0 },

    countText: {
      type: String,
      default: ""
    },

    pagination: {
      type: Object,
      required: false,
      validator({ total, current }) {
        return !!(total && current);
      }
    }
  },
  emits: {
    changePage: null,
    changePerPage: null
  },
  setup(props, { emit }) {
    let prevPerPage = PER_PAGE_COUNTS[0];

    function getRowsCountList() {
      return PER_PAGE_COUNTS.filter(n => n <= props.totalRows).map(count => ({
        id: `item-${count}`,
        name: count.toString(),
        selected: count === props.perPage
      }));
    }

    const rowsCountList = ref(getRowsCountList());

    function onConfigurationChange() {
      rowsCountList.value = getRowsCountList();
    }

    const selectedPerPage = computed(() => {
      const { name: page = 0 } =
        rowsCountList.value.find(({ selected }) => selected) || {};

      if (page === 0) {
        return prevPerPage;
      }

      return Number(page);
    });

    watch(() => props.perPage, onConfigurationChange);
    watch(() => props.totalRows, onConfigurationChange);

    watch(selectedPerPage, page => {
      prevPerPage = Number(page);
      emit("changePerPage", Number(page));
    });

    return { rowsCountList, selectedPerPage };
  },

  methods: {
    onRowsCountSelected(id) {
      this.rowsCountList.forEach(rowsCountItem => {
        rowsCountItem.selected = rowsCountItem.id === id;
      });
    },

    changePageHandler(page) {
      this.$emit("changePage", page);
    }
  }
};
</script>

<style lang="scss">
.pseudo-table--footer {
  border-top: 1px solid $border-color;
  position: relative;
  padding: 1.9rem 2.1rem 1.9rem 1.4rem;
  min-height: 90px;
  display: flex;
  align-items: center;

  .count-info {
    color: $grey-300;
    font: 400 0.93rem / 1 var(--theme-font);
  }

  .select-dropdown {
    .dropdown {
      border: 1px solid $color-contrast-dark;
    }
  }
}
</style>
